<template>
  <div>
    <!-- Stripe Payment Status -->
    <b-modal :no-close-on-backdrop="true" v-model="showPaymentModal" id="payment-status" ref="ref-payment-status" centered title="Payment Status"
      ok-only @ok="reloadPageWithoutParam">
      <section>
        <p v-if="payment_status === 'pending'">pending</p>
        <p v-if="payment_status === 'success'">success</p>
        <p v-if="payment_status === 'failed'">failed</p>
      </section>
    </b-modal>

    <!-- Pay Now -->
    <!-- <b-modal :no-close-on-backdrop="true" id="pay-now" ref="ref-pay-now" centered hide-footer>
      <section v-if="selectedPlan">
        <div class="pricing-card p-2 mb-2">
          <div class="pricing-plan-name">{{ selectedPlan.plan_name }}</div>
          <div
            class="pricing-cost mb-0"
            style="padding-top: 5px; padding-bottom: 20px"
            v-if="user_details.country === 'IN'"
          >
            <table style="width: 100%">
              <tbody>
                <tr>
                  <td class="text-center">Base Price :</td>
                  <td class="text-center">
                    {{
                      getSymbolFromCurrency(
                        selectedPlan.pp_currency
                          ? selectedPlan.pp_currency
                          : selectedPlan.plan_currency
                      )
                    }}
                    {{
                      addCommas(
                        selectedPlan.pp_price
                          ? removeGST(selectedPlan.pp_price)
                          : removeGST(selectedPlan.plan_price)
                      )
                    }}
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="text-center" style="color: #999">
                      + 18% GST :
                    </div>
                  </td>
                  <td class="text-center">
                    {{
                      getSymbolFromCurrency(
                        selectedPlan.pp_currency
                          ? selectedPlan.pp_currency
                          : selectedPlan.plan_currency
                      )
                    }}
                    {{
                      selectedPlan.pp_price
                        ? selectedPlan.pp_price -
                          removeGST(selectedPlan.pp_price)
                        : selectedPlan.plan_price -
                          removeGST(selectedPlan.plan_price)
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="pricing-btn">
            <button
              class="btn btn-pay-now w-100"
              @click="buyServicePlanStripe(selectedPlan)"
            >
              Pay Now
              {{
                getSymbolFromCurrency(
                  selectedPlan.pp_currency
                    ? selectedPlan.pp_currency
                    : selectedPlan.plan_currency
                )
              }}
              <span>
                {{
                  addCommas(
                    selectedPlan.pp_price
                      ? selectedPlan.pp_price
                      : selectedPlan.plan_price
                  )
                }}
              </span>
            </button>
          </div>
        </div>
      </section>
    </b-modal> -->

    <form action='https://secure.payu.in/_payment' target="_blank" method='post' v-show="false" ref="payu-form">
      <input type="hidden" name="key" :value="payUObj.key" />
      <input type="hidden" name="txnid" :value="payUObj.txnid" />
      <input type="hidden" name="productinfo" :value="payUObj.productinfo" />
      <input type="hidden" name="amount" :value="1" />
      <input type="hidden" name="email" :value="payUObj.email" />
      <input type="hidden" name="firstname" :value="payUObj.firstname" />
      <input type="hidden" name="lastname" :value="payUObj.lastname" />
      <input type="hidden" name="surl" :value="payUObj.surl" />
      <input type="hidden" name="furl" :value="payUObj.furl" />
      <input type="hidden" name="phone" :value="payUObj.phone" />
      <input type="hidden" name="hash" :value="payUObj.hash" />
      <input type="submit" value="submit" />
    </form>

    <!-- Buy Service for student -->
    <b-modal :no-close-on-backdrop="true" id="select-student-modal-id" ref="ref-select-student-modal" centered title="Select Students"
      no-close-on-backdrop @hidden="resetSelectedStudents" hide-footer>
      <v-select :reduce="(student) => student.student_user_id" label="unique_name" v-model="student_user_id"
        :options="assigned_students" :closeOnSelect="true">
        <template #option="data">
          <b-avatar :src="FILESURL + data.user_profile_image" size="16" class="align-middle mr-50" />
          <span> {{ data.unique_name }}</span>
        </template>
      </v-select>
      <br />
      <div v-if="student_user_id">
        <b-row>
          <!-- <b-col v-if="user_details.country === 'IN'" cols="12">
            <b-button @click="buyServicePlanRzrPay(plan.plan_id)">
              Pay Now
            </b-button>
          </b-col> -->
          <!-- v-else -->
          <b-col cols="12" class="d-flex justify-content-end mt-2">
            <b-button @click="buyServicePlanStripe(plan)" class="mr-1" variant="primary">Pay
              Via Stripe
            </b-button>
            <!-- <b-button @click="makePayUPayment(plan)" variant="primary">Pay Via PayU
            </b-button> -->
          </b-col>


          <!-- <b-col cols="12 mt-2">
            <p class="text-right font-weight-bold">NOTE: For UPI Payments choose PayU</p>
          </b-col> -->
        </b-row>
      </div>
    </b-modal>

    <b-tabs v-model="active_tab_index">
      <b-tab v-for="(service, index) in services" :title="service.service_name" :key="'services_tab_' + index">
        <div class="pricing-cards-grid">
          <div v-for="(plan, index) in service.plans" :key="'plan_' + plan.plan_id + index" class=""
            :class="{ 'third-card': index === 2 }">
            <b-card :class="{ 'h-100': index === 2 }">
              <div class="pricing-card p-2 mb-2">
                <div class="pricing-plan-name">{{ plan.plan_name }}</div>
                <div class="pricing-cost mb-0">
                  {{
                    getSymbolFromCurrency(
                      plan.pp_currency ? plan.pp_currency : plan.plan_currency
                    )
                  }}
                  <span v-if="
                    (plan.pp_price ? plan.pp_price : plan.plan_price) === null
                  ">
                    Free
                  </span>
                  <span v-else>
                    <span v-if="user_details.country === 'IN'">
                      {{
                        addCommas(
                          plan.pp_price
                            ? removeGST(plan.pp_price)
                            : removeGST(plan.plan_price)
                        )
                      }}
                    </span>
                    <span v-else>
                      {{
                        addCommas(
                          plan.pp_price ? plan.pp_price : plan.plan_price
                        )
                      }}
                    </span>
                  </span>
                </div>

                <div class="text-center" style="color: #999" v-if="
                  user_details.country === 'IN' && plan.plan_price !== null
                ">
                  + 18% GST
                </div>
                <div class="pricing-content" v-if="plan.plan_price !== null">
                  {{ plan.plan_description }}
                </div>
                <div class="pricing-btn">
                  <button v-if="
                    [
                      'agent',
                      'franchise',
                      'student_support',
                      'counselor',
                    ].includes(user_type) && plan.plan_price !== null
                  " class="btn btn-pay-now w-100" @click="showSelectStudentModal(plan)">
                    Buy For Student
                  </button>
                  <div v-if="user_type === 'student' && plan.plan_price !== null">
                    <!-- <button
      v-if="user_details.country === 'IN'"
      class="btn btn-pay-now w-100"
      @click="buyServicePlanRzrPay(plan.plan_id)"
    >
      Pay Now
    </button> -->
                    <!-- v-else -->
                    <button class="btn btn-pay-now w-100" @click="buyServicePlanStripe(plan)">
                      Buy Now
                    </button>
                  </div>
                </div>
              </div>
            </b-card>
          </div>
        </div>
        <b-card>
          <div class="pricing-plan-table animate__animated animate__pulse table-responsive">
            <table class="table">
              <thead class="header">
                <tr>
                  <th class="plan-lg-title">Compare Plans</th>
                  <th v-for="(plan, index) in service.plans" :key="'plan_compare_' + plan.plan_id + '_' + index">
                    <div class="text-center">
                      <div class="plan-name">{{ plan.plan_name }}</div>
                      <!-- <div class="plan-price">
                        {{
                          getSymbolFromCurrency(
                            plan.pp_currency
                              ? plan.pp_currency
                              : plan.plan_currency
                          )
                        }}
                        <span v-if="user_details.country === 'IN'">
                          {{
                            addCommas(
                              plan.pp_price
                                ? removeGST(plan.pp_price)
                                : removeGST(plan.plan_price)
                            )
                          }}
                        </span>
                        <span v-else>
                          {{
                            addCommas(
                              plan.pp_price ? plan.pp_price : plan.plan_price
                            )
                          }}
                        </span>
                      </div> -->
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody class="alternate-stripped" v-for="(feature, feature_index) in service.features"
                :key="'feature_' + feature.feature_id + '_' + feature_index">
                <tr>
                  <td>{{ feature.feature_name }}</td>
                  <td v-for="(plan, index) in service.plans" :key="index">
                    <span v-if="plan.plan_features[feature_index].extra_text">
                      {{ plan.plan_features[feature_index].extra_text }}
                    </span>
                    <img v-else-if="plan.plan_features[feature_index].spfm_id" src="@/assets/images/svg/blue-tick.svg"
                      class="img-fluid" />
                    <img v-else src="@/assets/images/svg/red-cross.svg" class="img-fluid" />
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <span>Services included are subject to change*</span>
              </tfoot>
            </table>
          </div>
        </b-card>
      </b-tab>

      <b-tab v-if="user_type === 'student'" title="Services Availed">
        <services-availed />
      </b-tab>

      <b-tab title="Accommodations">
        <accommodations />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import {
  BCarousel,
  BCarouselSlide,
  BButtonGroup,
  BButton,
  BCardText,
  BTabs,
  BTab,
  BCard,
  BCardHeader,
  BCardBody,
  BCollapse,
  BAvatar,
  BRow,
  BCol,
} from "bootstrap-vue";

import vSelect from "vue-select";
import ServicesAvailed from "./ServicesAvailed.vue";
import Accommodations from "./Accomodations.vue";
import Ripple from "vue-ripple-directive";
import HomeServices from "@/apiServices/HomeServices";
import StudentServices from "@/apiServices/StudentServices";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import { FILESURL } from "@/config";
import store from "@/store";
import PaymentServices from "@/apiServices/PaymentServices";
import mixin from "@/utils/mixins";
import getSymbolFromCurrency from "currency-symbol-map";
import CommonServices from "@/apiServices/CommonServices";

export default {
  components: {
    BCardCode,
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
    BCarousel,
    BCarouselSlide,
    BButtonGroup,
    BButton,
    BCardText,
    BTabs,
    BTab,
    BAvatar,
    BRow,
    BCol,
    ServicesAvailed,
    Accommodations,
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [mixin],
  data() {
    return {
      selectedPlan: null,

      active_tab_index: 0,

      student_user_id: null,
      plan: null,
      services: [],
      FILESURL,

      showPaymentModal: false,
      payment_status: "pending",

      payUObj: {
        key: "",
        surl: "",
        furl: "",
        amount: null,
        productinfo: "",
        firstname: "",
        lastname: "",
        email: "",
        phone: null,
        txnid: "",
        hash: ""
      }
    };
  },
  computed: {
    assigned_students() {
      return store.getters["user/getAssignedStudents"].data;
    },
    user_type() {
      if (store.getters["user/getUserDetails"]?.user_type) {
        return store.getters["user/getUserDetails"].user_type;
      } else {
        return null;
      }
    },
  },
  watch: {
    user_type(val) {
      this.watchUserType(val);
    },
  },

  methods: {
    getSymbolFromCurrency,

    removeGST(total) {
      let taxPercentage = 0.18;
      let withTaxAmount = total / (1 + taxPercentage);
      return withTaxAmount.toFixed(0);
    },

    watchUserType(val) {
      try {
        if (val) {
          const query = this.$route.query;
          const { transaction_id } = query;
          if (transaction_id && !isNaN(transaction_id)) {
            this.verifyTransactionStripe(transaction_id);
          }
        }
        this.getServicesAndPlans();
      } catch (err) {
        console.error(`Error in watchUserType `, err);
      }
    },

    getServicesAndPlans() {
      const user_details = this.$store.getters["user/getUserDetails"];

      const payload = {};
      if (user_details.country) {
        payload.country = user_details.country;
      }

      HomeServices.getServicesAndPlans(payload)
        .then((response) => {
          if (response.data.status) {
            this.services = response.data.data;
            this.$nextTick(() => {
              this.active_tab_index = 0;
              this.checkQuery();
            });
          }
        })
        .catch((err) => {
          console.error("Error in getting services and plans", err);
        });
    },

    checkQuery() {
      if (this.services && this.services.length) {
        const { service_name } = this.$route.query;
        const services = this.services;
        for (let i = 0; i < services.length; i++) {
          const s = services[i];
          if (s.service_name === service_name) {
            this.active_tab_index = i;
          }
        }
      }
    },

    showSelectStudentModal(plan) {
      this.plan = plan;
      this.$refs["ref-select-student-modal"].show();
      console.log({ r: this.$refs });
    },

    closeSelectStudentModal() {
      this.plan = {};
      this.$refs["ref-select-student-modal"].hide();
    },

    addCommas(amt) {
      return amt.toLocaleString("en-IN");
    },

    resetSelectedStudents() {
      this.student_user_id = null;
      this.plan_id = null;
    },

    async makePayUPayment(plan) {
      const response = await CommonServices.makePayUPayment()
      this.payUObj = response.data.data

      this.$nextTick(() => {
        this.$refs["payu-form"].submit()
      })
    },

    async buyServicePlanStripe(plan) {
      try {
        const url = window.location.href;

        if (this.user_type === "student") {
          const response = await StudentServices.makeStripePayment({
            plan_id: plan.plan_id,
            url,
          });
          window.location.href = response.data.data.session.url;
        } else if (
          ["agent", "franchise", "student_support", "counselor"].includes(
            this.user_type
          )
        ) {
          this.closeSelectStudentModal();
          const response = await CommonServices.makeStripePayment({
            plan_id: plan.plan_id,
            student_user_id: this.student_user_id,
            url,
          });
          window.location.href = response.data.data.session.url;
        }
      } catch (error) {
        console.error("Error in buyServicePlanStripe ", error);
      }
    },

    paymentCompleteRzrPay(response) {
      if (response.status) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Service Purchased",
            icon: "Check",
            variant: "success",
          },
        });
        this.reloadPageWithoutParam();
      } else {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Server Error",
            icon: "X",
            variant: "failure",
          },
        });
      }
    },

    buyServicePlanRzrPay(plan_id) {
      const userProfile = store.getters["user/getUserDetails"];
      if (userProfile.user_type === "student") {
        PaymentServices.displayRazorPay({
          plan_id,
          userProfile,
          razorpayPaymentResponse: this.paymentCompleteRzrPay,
        });
      } else if (
        ["agent", "franchise", "student_support", "counselor"].includes(
          userProfile.user_type
        ) &&
        this.student_user_id
      ) {
        if (!this.student_user_id) {
          return;
        }
        this.closeSelectStudentModal();
        PaymentServices.displayRazorPayForAgent({
          student_user_id: this.student_user_id,
          plan_id,
          userProfile,
          razorpayPaymentResponse: this.paymentCompleteRzrPay,
        });
      }
    },

    async verifyTransactionStripe(transaction_id) {
      this.showPaymentModal = true;

      try {
        let response;
        if (this.user_type === "student") {
          response = await StudentServices.verifyTransaction({
            transaction_id,
          });
        } else if (
          ["agent", "franchise", "student_support", "counselor"].includes(
            this.user_type
          )
        ) {
          response = await CommonServices.verifyTransaction({ transaction_id });
        }
        if (response?.data?.status) {
          this.payment_status = "success";
        } else {
          this.payment_status = "failed";
        }
      } catch (error) {
        console.error("Error in verifing transaction ", error);
      }
    },

    reloadPageWithoutParam() {
      window.location.replace(
        `${window.location.origin}${window.location.pathname}`
      );
    },
  },
  beforeMount() {
    this.watchUserType();
  },
};
</script>
<style>
.primary-color {
  color: #00d09c;
}

.pricing-cards-grid {
  display: grid;
  grid-template-columns: repeat(5, 33%);
  grid-template-rows: 1fr;
  grid-column-gap: 14px;
}

.pricing-cards-grid .third-card {
  height: 90%;
}

.pricing-card {
  background: linear-gradient(180deg, #ffffff 0%, #f8f8f8 100%);
  border-radius: 15px;
  /* box-shadow: -4px -4px 4px rgba(255, 255, 255, 0.13),
    4px 4px 11px rgba(0, 0, 0, 0.25); */
  /* font-family: "Montserrat", sans-serif; */
}

.pricing-card .pricing-plan-name {
  color: var(--primary);
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  margin-bottom: 12px;
}

.pricing-card .pricing-cost {
  font-size: 25px;
  text-align: center;
  margin-bottom: 15px;
  font-weight: 600;
  /* font-family: "Alice"; */
}

.pricing-card .pricing-content {
  font-size: 14px;
  margin: 10px 0px 25px;
  text-align: center;
}

.pricing-card .btn-pay-now {
  background-color: var(--primary);
  color: #fff;
  border-radius: 20px !important;
  font-weight: 500;
}

.pricing-card .plan-route {
  color: #5367ff;
  text-align: center;
  font-size: 14px;
  margin-top: 18px;
  cursor: pointer;
  font-weight: 500;
}

.pricing-section .pricing-slider .slick-list {
  margin: 0 -30px;
}

.pricing-section .pricing-slider .slick-slide {
  margin: 0 30px;
}

/***********PRICING PLAN TABLE************/

.pricing-plan-table {
  /* font-family: "Montserrat", sans-serif; */
}

.pricing-plan-table td {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}

/* .pricing-plan-table .header{
  text-align: center;
} */
.pricing-plan-table thead th {
  border-bottom: 1px solid transparent;
  border-top: 1px solid transparent;
}

.pricing-plan-table .plan-lg-title {
  font-size: 1.5rem;
  /* font-family: "Alice"; */
  font-weight: 600;
}

.pricing-plan-table .plan-name {
  color: var(--primary);
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 05px;
}

.pricing-plan-table .plan-price {
  /* font-family: "Alice"; */
  font-size: 22px;
  font-weight: 600;
}

.pricing-plan-table .btn-select-plan {
  background: #e1e1e1;
  border: 1px solid #465cff;
  border-radius: 20px;
  width: 100%;
  font-size: 15px;
  font-weight: 600;
}

.pricing-plan-table .header th {
  position: relative;
}

.pricing-plan-table .header th:after {
  content: "";
  height: 50%;
  width: 1px;
  position: absolute;
  right: 0;
  top: 50%;
  background-color: #00d09c;
}

.pricing-plan-table .header th:last-child:after {
  content: "";
  height: 50%;
  width: 0px;
  position: absolute;
  right: 0;
  top: 50%;
  background-color: #00d09c;
}

.pricing-plan-table .alternate-stripped .plan-detail {
  padding: 1.2rem 0 0.4rem 0;
  font-weight: bold;
  text-transform: uppercase;
}

.pricing-plan-table thead {
  position: sticky;
  top: 0;
  /* position: sticky !important; */
  /* top: 75px; */
  background-color: white;
  box-shadow: 0 0 16px rgb(0 0 0 / 10%);
}

.pricing-plan-table .alternate-stripped td {
  padding: 0.5rem 0;
  font-size: 14px;
  font-weight: 600;
  border-right: 1px solid #00d09c;
  text-align: center;
}

.pricing-plan-table .alternate-stripped td:last-child {
  border-right: 0px solid transparent;
}

.pricing-plan-table .alternate-stripped td:first-child {
  text-align: left;
}

.pricing-plan-table .alternate-stripped tr:nth-of-type(even) {
  background: rgba(83, 103, 255, 0.04);
}

.pricing-plan-table .table tbody+tbody {
  border-top: 1px solid transparent;
}

@media (max-width: 768px) {
  .pricing-cards-grid {
    grid-template-columns: repeat(3, 100%);
    overflow: auto;
  }
}
</style>
