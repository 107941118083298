import axiosInstance from "./Api";

export default {
  async getHomePageData() {
    let response = await axiosInstance().get(`/student/home-page-data`);

    return response;
  },

  async getRequiredDocs(payload) {
    let response = await axiosInstance().get(`/student/profile/required-docs/${payload.course_level_id}`);

    return response;
  },

  async getStudentProfile() {
    let response = await axiosInstance().get(`/student/profile`);

    return response;
  },

  async getCoursePreferences() {
    let response = await axiosInstance().get(`/student/course-preferences`);

    return response;
  },

  async saveStudentProfile(payload) {
    let response = await axiosInstance().post(`/student/profile`, payload);

    return response;
  },

  async getStudentStatus(payload) {
    let response = await axiosInstance().get(`/student/profile/status`);

    return response;
  },

  async uploadStudentProfilePicture(payload) {
    const {} = payload;

    let response = await axiosInstance().post(`/student/profile-image`);

    return response;
  },

  async getAssignedCounselors() {
    let response = await axiosInstance().get(`/student/profile/assigned-counselors`);

    return response;
  },

  async getCourses(payload) {
    let response = await axiosInstance().get(`/student/course/all`, {
      params: payload,
    });

    return response;
  },

  async getUniversityDetailsWithCourses(payload) {
    let response = await axiosInstance().get(
      `/student/course/university-details-with-courses/${payload.university_id}`
    );

    return response;
  },

  async ApplyCourse(payload) {
    const { course_id } = payload;

    let response = await axiosInstance().post(`/student/course/apply/${course_id}`, payload);

    return response;
  },

  async cancelApplication(payload) {
    const { course_id } = payload;

    let response = await axiosInstance().post(`/student/course/cancel/${course_id}`);

    return response;
  },

  async getAppliedCourses() {
    let response = await axiosInstance().get(`/student/course/all-applied`);

    return response;
  },

  async setApplicationsPriority(payload) {
    let response = await axiosInstance().post(`/student/course/application-priority`, payload);

    return response;
  },

  async getApplicationChat(payload) {
    const { sa_mapper_id } = payload;

    return axiosInstance().get(`/student/course/application/chat/${sa_mapper_id}`);
  },

  async sendApplicationChat(payload) {
    const { sa_mapper_id } = payload;

    return axiosInstance().post(`/student/course/application/chat/${sa_mapper_id}`, payload);
  },

  async getFavouriteCourses() {
    let response = await axiosInstance().get(`/student/course/favourite/all`);

    return response;
  },

  async addCourseToFavourite(payload) {
    const { course_id } = payload;

    let response = await axiosInstance().post(`/student/course/favourite/add/${course_id}`);

    return response;
  },

  async removeCourseFromFavourite(payload) {
    const { course_id } = payload;

    let response = await axiosInstance().post(`/student/course/favourite/remove/${course_id}`);

    return response;
  },

  async getSuggestedCourses() {
    let response = await axiosInstance().get(`/student/course/suggestions/all`);

    return response;
  },

  async acceptCourseSuggestion(payload) {
    const { course_id } = payload;

    let response = await axiosInstance().post(`/student/course/suggestions/accept/${course_id}`);

    return response;
  },

  async rejectCourseSuggestion(payload) {
    const { course_id } = payload;

    let response = await axiosInstance().post(`/student/course/suggestions/reject/${course_id}`);

    return response;
  },

  async getAllDocuments(payload) {
    let response = await axiosInstance().get(`/student/document/all`);

    return response;
  },

  async getStaffDocuments() {
    let response = await axiosInstance().get(`/student/document/staff/all`);

    return response;
  },

  async RemoveDocument(payload) {
    const { sd_mapper_id } = payload;

    let response = await axiosInstance().delete(`/student/document/remove/${sd_mapper_id}`);

    return response;
  },

  async getAllVisaDocuments(payload) {
    let response = await axiosInstance().get(`/student/visa-document/all`);

    return response;
  },

  async getStaffVisaDocuments() {
    let response = await axiosInstance().get(`/student/visa-document/staff/all`);

    return response;
  },

  async RemoveVisaDocument(payload) {
    const { svd_id } = payload;

    let response = await axiosInstance().delete(`/student/visa-document/remove/${svd_id}`);

    return response;
  },

  async applicationChecklist() {
    return await axiosInstance().get(`/student/application-checklist`);
  },

  async visaChecklist() {
    return await axiosInstance().get(`/student/visa-checklist`);
  },

  async getServicesAvailed() {
    let response = await axiosInstance().get(`/student/services/availed`);

    return response;
  },

  async getCompletedFeatures() {
    let response = await axiosInstance().get(`/student/services/completed-features`);

    return response;
  },

  async makeStripePayment(payload) {
    const { plan_id, url } = payload;

    let response = await axiosInstance().get(`/student/stripe-payment/make/${plan_id}`, { params: { url } });

    return response;
  },

  async verifyTransaction(payload) {
    const { transaction_id } = payload;

    let response = await axiosInstance().get(`/student/stripe-payment/verify/${transaction_id}`);

    return response;
  },

  async saveReferenceLead(payload) {
    return await axiosInstance().post(`/student/save-reference-lead`, payload);
  },
};
